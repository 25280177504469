import { useCallback } from "react";
import "./FormContainer.css";

const FormContainer = () => {

  // const onDivcustomerSectionClick = useCallback(() => {
  //   const anchor = document.querySelector(
  //     "[data-scroll-to='divcustomerSection']"
  //   );
  //   if (anchor) {
  //     anchor.scrollIntoView({ block: "start" });
  //   }
  // }, []);

  const onFeaturesTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='featuresText']");
    if (anchor) {
      anchor.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, []);

  const onItemLinkClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='itemLink']");
    if (anchor) {
      anchor.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, []);

  const onResourcesTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='resourcesText']");
    if (anchor) {
      anchor.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, []);

  const onLinkPricingClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='linkPricing']");
    if (anchor) {
      anchor.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, []);

  return (
    <div className="diveatapp-header-global-sec">
      <div className="divpage-center9">
        <div className="diveatapp-header-inner">
          <div className="diveat-app-logo">
            <section className="link7" />
          </div>
          <div className="divmneu-main-headermargin">
            <div className="divmneu-main-header">
              <div className="diveat-app-menu-section">
                <div className="list">
                  <div className="itemmargin">
                    <div className="item">
                      <div className="link8">
                        <div className="features" onClick={onFeaturesTextClick}>
                          Features
                          <div
                            className="anchor-features"
                            data-scroll-to="featuresText"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="itemmargin1">
                    <div className="features" onClick={onItemLinkClick}>
                      How it works
                      <div
                        className="anchor-item-link-how-it-wo"
                        data-scroll-to="itemLink"
                      />
                    </div>
                  </div>
                  <div className="itemmargin">
                    <div className="item1">
                      <div className="link8">
                        <div
                          className="features"
                          onClick={onResourcesTextClick}
                        >
                          Resources
                          <div
                            className="anchor-resources"
                            data-scroll-to="resourcesText"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item2">
                    <div className="features" onClick={onLinkPricingClick}>
                      Pricing
                      <div
                        className="anchor-link-pricing"
                        data-scroll-to="linkPricing"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="diveat-app-head-ctamargin">
                <div className="diveat-app-head-cta">
                  <div className="spanhs-cos-wrapper-module-169">
                    <div className="spanhs-cta-4061114a-c5d0-48e7">
                      <div className="link10">
                        <div className="try-for-free">Request Demo</div>
                      </div>
                    </div>
                  </div>
                  <div className="spanhs-cos-wrapper-module-1691">
                    <div className="spanhs-cta-58270d17-5d39-4474">
                      <div className="link11">
                        <div className="try-for-free">Try for free</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormContainer;
