import "./FeatureCardForm.css";

const FeatureCardForm = () => {
  return (
    <div className="divrow-fluid-wrapper">
      <div className="divrow-fluid">
        <div className="divspan5">
          <div className="divspan122">
            <div className="divhs-cos-wrapper-module-6923">
              <div className="link13">
                <img
                  className="eat20app20logo203webp-icon"
                  alt=""
                  src="/eat20app20logo203webp@2x.png"
                />
              </div>
            </div>
            <div className="divhs-cos-wrapper-module-1692">
              <div className="spanhs-cos-wrapper-module-1692">
                <div className="p10">
                  <div className="empowering-restaurants-one-container">
                    <p className="empowering-restaurants-one">
                      Empowering restaurants, one table at a time
                    </p>
                    <p className="empowering-restaurants-one">
                      Discover seamless dining with Eat App
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="divhs-cos-wrapper-module-1692">
              <div className="spanhs-cos-wrapper-module-1692">
                <div className="p11">
                  <img className="link-icon" alt="" src="/link1@2x.png" />
                  <img className="link-icon" alt="" src="/link2@2x.png" />
                  <img className="link-icon" alt="" src="/link3@2x.png" />
                  <div className="link14">
                    <img
                      className="youtubepng-icon"
                      alt=""
                      src="/youtubepng@2x.png"
                    />
                  </div>
                  <div className="link15">
                    <img
                      className="linkedinpng-icon"
                      alt=""
                      src="/linkedinpng@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="divhs-cos-wrapper-module-16922">
              <div className="spanhs-cos-wrapper-module-1694">
                <div className="divleft-badg">
                  <div className="link16">
                    <div className="google-paysvg">
                      <img
                        className="google-paysvg-icon"
                        alt=""
                        src="/googlepaysvg.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="divleft-badg">
                  <div className="link17">
                    <div className="app-storesvg">
                      <img
                        className="app-storesvg-icon"
                        alt=""
                        src="/appstoresvg.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divspan7">
          <div className="divrow-fluid-wrapper1">
            <div className="divrow-fluid1">
              <div className="divspan123">
                <div className="divhs-cos-wrapper-module-1692">
                  <div className="spanhs-cos-wrapper-module-249">
                    <div className="heading-51">
                      <div className="company">Features</div>
                    </div>
                  </div>
                </div>
                <div className="divhs-cos-wrapper-module-2706">
                  <div className="spanhs-cos-wrapper-module-249">
                    <div className="navigation-navigation-menu">
                      <div className="nonehs-menu-item">
                        <div className="menuitem">
                          <div className="reservation-system">
                            Reservation system
                          </div>
                        </div>
                      </div>
                      <div className="nonehs-menu-item">
                        <div className="menuitem1">
                          <div className="reservation-system">
                            Table management
                          </div>
                        </div>
                      </div>
                      <div className="nonehs-menu-item">
                        <div className="menuitem2">
                          <div className="reservation-system">
                            CRM and guest profiles
                          </div>
                        </div>
                      </div>
                      <div className="nonehs-menu-item">
                        <div className="menuitem3">
                          <div className="reservation-system">Marketing</div>
                        </div>
                      </div>
                      <div className="nonehs-menu-item">
                        <div className="menuitem4">
                          <div className="reservation-system">{`Reports & trends`}</div>
                        </div>
                      </div>
                      <div className="nonehs-menu-item">
                        <div className="menuitem5">
                          <div className="reservation-system">Waitlist</div>
                        </div>
                      </div>
                      <div className="menuitem6">
                        <div className="reservation-system">Integrations</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="divspan3">
                <div className="divrow-fluid-wrapper2">
                  <div className="divspan124">
                    <div className="divhs-cos-wrapper-module-1692">
                      <div className="spanhs-cos-wrapper-module-249">
                        <div className="heading-52">
                          <div className="company">Company</div>
                        </div>
                      </div>
                    </div>
                    <div className="divhs-cos-wrapper-module-2706">
                      <div className="spanhs-cos-wrapper-module-249">
                        <div className="navigation-navigation-menu">
                          <div className="nonehs-menu-item">
                            <div className="menuitem7">
                              <div className="reservation-system">About us</div>
                            </div>
                          </div>
                          <div className="nonehs-menu-item">
                            <div className="menuitem8">
                              <div className="reservation-system">
                                Contact us
                              </div>
                            </div>
                          </div>
                          <div className="nonehs-menu-item">
                            <div className="menuitem9">
                              <div className="reservation-system">
                                Privacy policy
                              </div>
                            </div>
                          </div>
                          <div className="nonehs-menu-item">
                            <div className="menuitem10">
                              <div className="reservation-system">
                                Terms of service
                              </div>
                            </div>
                          </div>
                          <div className="nonehs-menu-item">
                            <div className="menuitem11">
                              <div className="reservation-system">Careers</div>
                            </div>
                          </div>
                          <div className="nonehs-menu-item">
                            <div className="menuitem12">
                              <div className="reservation-system">Demo</div>
                            </div>
                          </div>
                          <div className="menuitem13">
                            <div className="reservation-system">Pricing</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="divspan3">
                <div className="divrow-fluid-wrapper2">
                  <div className="divspan124">
                    <div className="divhs-cos-wrapper-module-1692">
                      <div className="spanhs-cos-wrapper-module-249">
                        <div className="heading-53">
                          <div className="company">Resources</div>
                        </div>
                      </div>
                    </div>
                    <div className="divhs-cos-wrapper-module-2706">
                      <div className="spanhs-cos-wrapper-module-249">
                        <div className="navigation-navigation-menu">
                          <div className="nonehs-menu-item">
                            <div className="menuitem14">
                              <div className="reservation-system">
                                <p className="empowering-restaurants-one">
                                  The 16 Best
                                </p>
                                <p className="empowering-restaurants-one">
                                  Reservation Systems
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="nonehs-menu-item">
                            <div className="menuitem15">
                              <div className="reservation-system">
                                <p className="empowering-restaurants-one">
                                  Guide to Restaurant
                                </p>
                                <p className="empowering-restaurants-one">
                                  Marketing
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="nonehs-menu-item">
                            <div className="menuitem16">
                              <div className="reservation-system">
                                <p className="empowering-restaurants-one">
                                  Guide to Customer
                                </p>
                                <p className="empowering-restaurants-one">
                                  Service
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="nonehs-menu-item">
                            <div className="menuitem17">
                              <div className="reservation-system">
                                <p className="empowering-restaurants-one">
                                  Guide to Making a
                                </p>
                                <p className="empowering-restaurants-one">
                                  Restaurant Website
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="menuitem18">
                            <div className="reservation-system">
                              All articles
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="divspan3">
                <div className="divrow-fluid-wrapper2">
                  <div className="divspan124">
                    <div className="divhs-cos-wrapper-module-1692">
                      <div className="spanhs-cos-wrapper-module-249">
                        <div className="heading-54">
                          <div className="company">Compare us</div>
                        </div>
                      </div>
                    </div>
                    <div className="divhs-cos-wrapper-module-2706">
                      <div className="spanhs-cos-wrapper-module-249">
                        <div className="navigation-navigation-menu">
                          <div className="nonehs-menu-item">
                            <div className="menuitem18">
                              <div className="reservation-system">
                                OpenTable
                              </div>
                            </div>
                          </div>
                          <div className="nonehs-menu-item">
                            <div className="menuitem20">
                              <div className="reservation-system">Resy</div>
                            </div>
                          </div>
                          <div className="nonehs-menu-item">
                            <div className="menuitem21">
                              <div className="reservation-system">Yelp</div>
                            </div>
                          </div>
                          <div className="nonehs-menu-item">
                            <div className="menuitem22">
                              <div className="reservation-system">
                                Seven Rooms
                              </div>
                            </div>
                          </div>
                          <div className="nonehs-menu-item">
                            <div className="menuitem23">
                              <div className="reservation-system">ResOs</div>
                            </div>
                          </div>
                          <div className="menuitem24">
                            <div className="reservation-system">
                              Compare All
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCardForm;
