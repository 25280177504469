import { useMemo } from "react";
import "./FreeContainer.css";

const FreeContainer = ({
  free,
  increaseBookingsAndGetSta,
  managementForFree,
  checkGreensvg,
  coversPerMonth,
  emailConfirmations,
  checkGreensvg1,
  tableManagement,
  prop,
  propJustifyContent,
  propGap,
  propWidth,
  propFlex,
  propFlex1,
  propJustifyContent1,
  propGap1,
  propJustifyContent2,
  propGap2,
  propJustifyContent3,
  propGap3,
  propFlex2,
  propWidth1,
  propFlex3,
}) => {
  const divlistStyle = useMemo(() => {
    return {
      justifyContent: propJustifyContent,
      gap: propGap,
    };
  }, [propJustifyContent, propGap]);

  const checkGreensvgStyle = useMemo(() => {
    return {
      width: propWidth,
      flex: propFlex,
    };
  }, [propWidth, propFlex]);

  const coversPerMonthStyle = useMemo(() => {
    return {
      flex: propFlex1,
    };
  }, [propFlex1]);

  const divlist1Style = useMemo(() => {
    return {
      justifyContent: propJustifyContent1,
      gap: propGap1,
    };
  }, [propJustifyContent1, propGap1]);

  const divlist2Style = useMemo(() => {
    return {
      justifyContent: propJustifyContent2,
      gap: propGap2,
    };
  }, [propJustifyContent2, propGap2]);

  const divlist3Style = useMemo(() => {
    return {
      justifyContent: propJustifyContent3,
      gap: propGap3,
    };
  }, [propJustifyContent3, propGap3]);

  const checkGreensvg1Style = useMemo(() => {
    return {
      flex: propFlex2,
      width: propWidth1,
    };
  }, [propFlex2, propWidth1]);

  const tableManagementStyle = useMemo(() => {
    return {
      flex: propFlex3,
    };
  }, [propFlex3]);

  return (
    <div className="divcolumn-inner">
      <div className="divhead">
        <div className="heading-5">
          <div className="free">{free}</div>
        </div>
        <div className="p9">
          <div className="increase-bookings-and-container">
            <p className="increase-bookings-and">{increaseBookingsAndGetSta}</p>
            <p className="increase-bookings-and">{managementForFree}</p>
          </div>
        </div>
      </div>
      <div className="divlist-group1">
        <div className="divlist6" style={divlistStyle}>
          <div className="check-greensvg" style={checkGreensvgStyle}>
            <img className="check-greensvg-icon" alt="" src={checkGreensvg} />
          </div>
          <div className="covers-per-month" style={coversPerMonthStyle}>
            {coversPerMonth}
          </div>
        </div>
        <div className="divlist7" style={divlist1Style}>
          <div className="check-greensvg">
            <img
              className="check-greensvg-icon"
              alt=""
              src="/check-greensvg.svg"
            />
          </div>
          <div className="covers-per-month">
            Connect Google, Facebook and Instagram
          </div>
        </div>
        <div className="divlist8" style={divlist2Style}>
          <div className="check-greensvg">
            <img
              className="check-greensvg-icon"
              alt=""
              src="/check-greensvg.svg"
            />
          </div>
          <div className="covers-per-month">{emailConfirmations}</div>
        </div>
        <div className="divlist9" style={divlist3Style}>
          <div className="check-greensvg3" style={checkGreensvg1Style}>
            <img className="check-greensvg-icon" alt="" src={checkGreensvg1} />
          </div>
          <div className="table-management" style={tableManagementStyle}>
            {tableManagement}
          </div>
        </div>
        <div className="divlist10">
          <div className="check-greensvg">
            <img
              className="check-greensvg-icon"
              alt=""
              src="/check-greensvg.svg"
            />
          </div>
          <div className="covers-per-month">Online reservation management</div>
        </div>
      </div>
      <div className="divprice-monthmargin">
        <div className="divprice-month">
          <div className="div">{prop}</div>
          <div className="mo">/mo</div>
        </div>
      </div>
    </div>
  );
};

export default FreeContainer;
