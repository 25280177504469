import "./OnlineBookingsContainer.css";

const OnlineBookingsContainer = ({
  bookingOptions,
  reservationFlowDescriptio,
  bookingBenefits,
  bookingOptionDescription,
}) => {
  return (
    <div className="divonlinebookings">
      <div className="divleft-sec2">
        <div className="online-bookings1">{bookingOptions}</div>
        <div className="frame3">
          <div className="p5">
            <div className="commission-free-reservations-f-container">
              <p className="commission-free-reservations-f">
                {reservationFlowDescriptio}
              </p>
              <p className="commission-free-reservations-f">
                {bookingBenefits}
              </p>
            </div>
          </div>
          <div className="link12">
            <div className="learn-more">Learn more</div>
          </div>
        </div>
      </div>
      <img className="divimg-sec-icon" alt="" src={bookingOptionDescription} />
    </div>
  );
};

export default OnlineBookingsContainer;
