import { useCallback, useEffect } from "react";
import FormContainer from "../components/FormContainer";
import OnlineBookingsContainer from "../components/OnlineBookingsContainer";
import CardContainer from "../components/CardContainer";
import RealTimeSupportContainer from "../components/RealTimeSupportContainer";
import FreeContainer from "../components/FreeContainer";
import FeatureCardForm from "../components/FeatureCardForm";
import Footer from "../components/Footer";
import "./RestauranteatappcoByHtmlt.css";
import 'bootstrap/dist/css/bootstrap.css';

const RestauranteatappcoByHtmlt = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onDivcustomerSectionClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='divcustomerSection']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start" });
    }
  }, []);

  return (
    <div className="honey-bee-v2">
      <div className="divbody-container-wrapper">
        <div className="divheader-container" />
        <FormContainer />
        <div className="divspan12">
          <div className="divlist-group">
            <main className="divbanner-section-outer" id="Hero Top">
              <div className="divpage-center">
                <div className="divbanner-section-inner">
                  <div className="frame" data-animate-on-scroll>
                    <div className="divbanner-conn">
                      <div className="pseudo" />
                      <div className="pseudo1" />
                      <div className="heading-4">
                        <div className="for-restaurants-fb">{`For Restaurants, F&B Groups, Hotels`}</div>
                      </div>
                      <div className="heading-1">
                        <div className="grow-your-venue-container">
                          <p className="grow-your-venue">Grow your Venue</p>
                          <p className="grow-your-venue">with Eat App</p>
                        </div>
                      </div>
                      <div className="p">
                        <div className="the-end-to-end-dining-container">
                          <p className="grow-your-venue">
                            The end-to-end dining experience solution:
                            reservation management,
                          </p>
                          <p className="grow-your-venue">{`marketing, review, payment suite & much more...`}</p>
                        </div>
                      </div>
                    </div>
                    <div className="divcta-button-outer">
                      <div className="spanhs-cta-3b9e4140-04d4-4d3e">
                        <div className="link">
                          <div className="start-for-free">Start for free</div>
                          <div className="arrow-downsvg">
                            <img
                              className="arrow-downsvg-icon"
                              alt=""
                              src="/arrowdownsvg.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="divbottom-text">
                        <div className="no-credit-card">
                          No credit card required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="divbanner-img" data-animate-on-scroll>
                    <div className="pseudo2" />
                    <img
                      className="divjs-logo-icon"
                      alt=""
                      src="/divjslogo@2x.png"
                    />
                    <img
                      className="divbanner-inner-image-icon"
                      alt=""
                      src="/divbannerinnerimage@2x.png"
                    />
                  </div>
                </div>
              </div>
            </main>
            <section className="divtabbg1" id="Features of App">
              <div className="divpage-center1" data-animate-on-scroll>
                <div className="divtab-slider-inner">
                  <div className="divheader-sec">
                    <div className="heading-2">
                      <div className="increase-your-reservations-container">
                        <p className="grow-your-venue">Increase your</p>
                        <p className="grow-your-venue">{`reservations & profit`}</p>
                      </div>
                    </div>
                  </div>
                  <div className="divtab-slider-main">
                    <div className="divtabs-wrapper-outer-scroll">
                      <div className="divtabs-wrapper">
                        <div className="divtabs-wrapper-inner">
                          <div className="divtab-button">
                            <div className="start-for-free">
                              Online bookings
                            </div>
                          </div>
                          <div className="divtab-button1">
                            <div className="start-for-free">
                              Manage reservations
                            </div>
                          </div>
                          <div className="divtab-button1">
                            <div className="start-for-free">Remind guests</div>
                          </div>
                          <div className="divtab-button1">
                            <div className="start-for-free">Manage tables</div>
                          </div>
                          <div className="divtab-button4">
                            <div className="start-for-free">
                              Build guest database
                            </div>
                          </div>
                          <div className="divtab-button1">
                            <div className="start-for-free">Promote brand</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <OnlineBookingsContainer
                      bookingOptions="Online bookings"
                      reservationFlowDescriptio="Commission-free reservations from Instagram, Google and your website."
                      bookingBenefits="Get more direct reservations and cut out the cover fees."
                      bookingOptionDescription="/divimgsec@2x.png"
                    />
                    <div className="divdotsmargin">
                      <div className="divdots">
                        <div className="divdotmargin">
                          <div className="divdot" />
                        </div>
                        <div className="divdotmargin">
                          <div className="divdot1" />
                        </div>
                        <div className="divdotmargin">
                          <div className="divdot1" />
                        </div>
                        <div className="divdotmargin">
                          <div className="divdot1" />
                        </div>
                        <div className="divdotmargin">
                          <div className="divdot1" />
                        </div>
                        <div className="divdotmargin">
                          <div className="divdot1" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="divcta-field-outer">
                    <div className="spanhs-cta-4312624e-48b2-4ecb">
                      <div className="link1">
                        <div className="start-for-free">Start for free</div>
                        <div className="arrow-downsvg">
                          <img
                            className="arrow-downsvg-icon"
                            alt=""
                            src="/arrowdownsvg1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="diveat-empowers-sec" data-animate-on-scroll>
              <div className="eat-app-helps">Eat App helps you</div>
              <div className="divpage-center2">
                <div className="diveat-empowers-inner">
                  <div className="divleft-sec">
                    <div className="heading-21">
                      <div className="divlist" data-animate-on-scroll>
                        <div className="divtitle">
                          <div className="span">
                            <div className="bx-trending-upsvg">
                              <img
                                className="bx-trending-upsvg-icon"
                                alt=""
                                src="/bx-trendingupsvg.svg"
                              />
                            </div>
                          </div>
                          <div className="get-more-bookings">
                            Get more bookings
                          </div>
                        </div>
                        <div className="p1">
                          <div className="with-real-time-website-container">
                            <p className="grow-your-venue">
                              With real-time website bookings, as well as Google
                              and social media
                            </p>
                            <p className="grow-your-venue">
                              integrations, we help you increase direct,
                              commission-free reservations.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="divlist-group">
                      <div className="divlist1">
                        <div className="divtitle1">
                          <div className="span1">
                            <div className="increasesvg">
                              <img
                                className="increasesvg-icon"
                                alt=""
                                src="/increasesvg.svg"
                              />
                            </div>
                          </div>
                          <div className="increase-repeat-visits">
                            Increase repeat visits
                          </div>
                        </div>
                        <div className="p2">
                          <div className="through-memorable-guest-container">
                            <span className="through-memorable-guest-container1">
                              <p className="grow-your-venue">
                                Through memorable guest experiences, with guest
                                retention tools like post-
                              </p>
                              <p className="grow-your-venue">
                                dining surveys and targeted email marketing.
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="divlist2">
                    <div className="divtitle2">
                      <div className="span2">
                        <div className="increasesvg">
                          <img
                            className="automatesvg-icon"
                            alt=""
                            src="/automatesvg.svg"
                          />
                        </div>
                      </div>
                      <div className="automate-your-venue">
                        Automate your venue
                      </div>
                    </div>
                    <div className="p3">
                      <div className="with-real-time-website-container">
                        <p className="grow-your-venue">
                          By automating common and repetitive tasks like tagging
                          guests, sending
                        </p>
                        <p className="grow-your-venue">
                          messages or capturing payments.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divhospitality-brands-sec" data-animate-on-scroll>
              <div className="divpage-center3">
                <div className="heading-22">
                  <div className="grow-your-venue-container">
                    For hospitality brands of all sizes
                  </div>
                </div>
                <div className="divrepeater-sec">
                  <CardContainer
                    dimensionCode="/restaurants20iconwebp@2x.png"
                    locationType="Restaurants"
                  />
                  <CardContainer
                    dimensionCode="/hotels20iconwebp@2x.png"
                    locationType="Hotels"
                  />
                  <CardContainer
                    dimensionCode="/f26b20group20iconwebp@2x.png"
                    locationType={`F&B Groups`}
                  />
                  <CardContainer
                    dimensionCode="/bars202620wineries20iconwebp@2x.png"
                    locationType={`Bars & Wineries`}
                  />
                  <CardContainer
                    dimensionCode="/communities20iconwebp@2x.png"
                    locationType="Communities"
                  />
                </div>
              </div>
            </div>
            <div
              className="divhs-cos-wrapper-widget-1692"
              data-animate-on-scroll
            >
              <div className="divstarted-timing-section">
                <div className="divpage-center4">
                  <div className="divstarted-timing-inner">
                    <div className="divleft-sec1">
                      <div className="heading-23">
                        <div className="increase-your-reservations-container">
                          <p className="grow-your-venue">Get started in 15</p>
                          <p className="grow-your-venue">minutes</p>
                        </div>
                      </div>
                      <div className="divlsit-group">
                        <div className="divlist3">
                          <img
                            className="set20up20shifts20iconwebp"
                            alt=""
                            src="/set20up20shifts20iconwebp@2x.png"
                          />
                          <div className="set-up-shifts">{`Set up shifts & floor plan`}</div>
                        </div>
                        <div className="divlist4">
                          <img
                            className="set20up20shifts20iconwebp"
                            alt=""
                            src="/connect20booking20channels20iconwebp@2x.png"
                          />
                          <div className="connect-your-booking">
                            Connect your booking channels
                          </div>
                        </div>
                        <div className="divlist5">
                          <img
                            className="set20up20shifts20iconwebp"
                            alt=""
                            src="/import20guests20iconwebp@2x.png"
                          />
                          <div className="set-up-shifts">{`Import your guests & reservations`}</div>
                        </div>
                      </div>
                      <div className="spanhs-cta-cfc4ffbb-85ef-4e9c">
                        <div className="link2">
                          <div className="get-started">Get Started</div>
                        </div>
                      </div>
                    </div>
                    <div className="divright-sec">
                      <img
                        className="get20started20in20520minut-icon"
                        alt=""
                        src="/get20started20in20520minutes20with20eat20appwebp@2x.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <section
                className="divproblem-solving-section"
                id="How it Works"
                data-animate-on-scroll
              >
                <div className="divpage-center5">
                  <div className="heading-24">
                    <div className="helping-operators-run-container">
                      <p className="grow-your-venue">Helping operators</p>
                      <p className="grow-your-venue">
                        run more efficient restaurants 
                      </p>
                    </div>
                  </div>
                  <div className="divicon-list-repeater">
                    <RealTimeSupportContainer
                      see20everything20in20once="/see20everything20in20once20place20iconpng@2x.png"
                      heading4SeeEverythingInOn="See everything in one place"
                      stopJugglingMultipleAppsS="Stop juggling multiple apps, sheets and reservation books."
                      bringItAllIntoOneEasyToUs="Bring it all into one easy to use system."
                      real20time20support20icon="/real20time20support20iconpng@2x.png"
                      heading4GetRealTimeSuppor="Get real-time support"
                      wereOnHand247ToHelpAlongT="We’re on hand 24/7 to help along the way with fast and helpful"
                      inAppCustomerSupport="in-app customer support."
                    />
                    <RealTimeSupportContainer
                      see20everything20in20once="/track20vips20iconpng@2x.png"
                      heading4SeeEverythingInOn="Track VIPs and regulars"
                      stopJugglingMultipleAppsS="Keep your best customers happy with automated tags and a"
                      bringItAllIntoOneEasyToUs="powerful guest database."
                      real20time20support20icon="/reduce20no20show20iconpng@2x.png"
                      heading4GetRealTimeSuppor="Reduce no-shows"
                      wereOnHand247ToHelpAlongT="Our in-built no-show prevention tools drastically reduce high"
                      inAppCustomerSupport="no-show rates."
                    />
                    <RealTimeSupportContainer
                      see20everything20in20once="/see20performance20iconspng@2x.png"
                      heading4SeeEverythingInOn="See your performance"
                      stopJugglingMultipleAppsS="Unlock the power of data for making better business decisions"
                      bringItAllIntoOneEasyToUs="and continiously improve."
                      real20time20support20icon="/use20any20device20iconpng@2x.png"
                      heading4GetRealTimeSuppor="Use any device"
                      wereOnHand247ToHelpAlongT="Most other systems run on iPad only. Eat App is synced across"
                      inAppCustomerSupport="iPhone, Android, Web browsers and iPad."
                    />
                  </div>
                </div>
              </section>
              <section
                className="divtabbg2"
                id="Resources"
                data-animate-on-scroll
              >
                <div className="divpage-center6">
                  <div className="divtab-slider-inner">
                    <div className="divheader-sec1">
                      <div className="increase-your-reservations-container">
                        Features for the future of hospitality
                      </div>
                    </div>
                    <div className="divtab-slider-main">
                      <div className="divtabs-wrapper-outer-scroll">
                        <div className="divtabs-wrapper1">
                          <div className="divtabs-wrapper-inner1">
                            <div className="divtab-button">
                              <div className="start-for-free">Pre payments</div>
                            </div>
                            <div className="divtab-button1">
                              <div className="start-for-free">
                                Guest reviews
                              </div>
                            </div>
                            <div className="divtab-button1">
                              <div className="start-for-free">Pay at table</div>
                            </div>
                            <div className="divtab-button1">
                              <div className="start-for-free">
                                Review at table
                              </div>
                            </div>
                            <div className="divtab-button1">
                              <div className="start-for-free">Reports</div>
                            </div>
                            <div className="divtab-button1">
                              <div className="start-for-free">Marketing</div>
                            </div>
                            <div className="divtab-button1">
                              <div className="start-for-free">For groups</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <OnlineBookingsContainer
                        bookingOptions="Pre payments"
                        reservationFlowDescriptio="Upfront payments during the reservation flow including deposits and pre-"
                        bookingBenefits="paid bookings. Run pre-paid events and reduce no-shows."
                        bookingOptionDescription="/divimgsec1@2x.png"
                      />
                      <div className="divdotsmargin">
                        <div className="divdots1">
                          <div className="divdotmargin">
                            <div className="divdot" />
                          </div>
                          <div className="divdotmargin">
                            <div className="divdot1" />
                          </div>
                          <div className="divdotmargin">
                            <div className="divdot1" />
                          </div>
                          <div className="divdotmargin">
                            <div className="divdot1" />
                          </div>
                          <div className="divdotmargin">
                            <div className="divdot1" />
                          </div>
                          <div className="divdotmargin">
                            <div className="divdot1" />
                          </div>
                          <div className="divdotmargin">
                            <div className="divdot1" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="divcta-sec">
                      <div className="spanhs-cta-4312624e-48b2-4ecb">
                        <div className="link3">
                          <img
                            className="pseudo-icon"
                            alt=""
                            src="/pseudo@2x.png"
                          />
                          <div className="get-started1">Get Started</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="divcustomer-section"
                id="Pricing"
                data-animate-on-scroll
              >
                <div className="divpage-center7">
                  <div className="heading-26">
                    <div className="increase-your-reservations-container">
                      Get more customers, for less
                    </div>
                  </div>
                  <div className="frame1">
                    <div className="frame2">
                      <div className="divcustomer-section-repeater">
                        <div className="pseudo3" />
                        <div className="divslick-list">
                          <div className="divslick-track">
                            <FreeContainer
                              free="Free"
                              increaseBookingsAndGetSta="Increase bookings and get started with table"
                              managementForFree="management for free."
                              checkGreensvg="/check-greensvg.svg"
                              coversPerMonth="30 covers per month"
                              emailConfirmations="Email confirmations"
                              checkGreensvg1="/check-greensvg1.svg"
                              tableManagement="Table management"
                              prop="$0"
                            />
                            <FreeContainer
                              free="Starter"
                              increaseBookingsAndGetSta="Get more bookings and start sending SMS to"
                              managementForFree="gain guest loyalty."
                              checkGreensvg="/check-greensvg2.svg"
                              coversPerMonth="300 covers per month"
                              emailConfirmations="SMS and Email confirmations"
                              checkGreensvg1="/check-greensvg.svg"
                              tableManagement={`Table & guest management`}
                              prop="$69"
                              propJustifyContent="flex-start"
                              propGap="12px"
                              propWidth="unset"
                              propFlex="1"
                              propFlex1="1"
                              propJustifyContent1="flex-start"
                              propGap1="12px"
                              propJustifyContent2="space-between"
                              propGap2="unset"
                              propJustifyContent3="space-between"
                              propGap3="unset"
                              propFlex2="unset"
                              propWidth1="20px"
                              propFlex3="unset"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="divbottom-text-cta">
                      <div className="p4">
                        <div className="not-sure-what">{`Not sure what plan is best for you? `}</div>
                      </div>
                      <div className="divcta-sec">
                        <div className="spanhs-cta-4312624e-48b2-4ecb">
                          <div className="link4">
                            <div className="start-for-free">Compare plans</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="divcta-section-outer" data-animate-on-scroll>
                <div className="divpage-center8">
                  <div className="divadd-content">
                    <div className="heading-27">
                      <div className="grow-your-venue-container">
                        <p className="grow-your-venue">
                          Streamline your restaurant
                        </p>
                        <p className="grow-your-venue">operations today</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divcta-repeater">
                  <div className="divsingle-cta">
                    <div className="spanhs-cta-ae80ca34-8e08-402c">
                      <div className="link5">
                        <div className="start-for-free">Get Started</div>
                      </div>
                    </div>
                  </div>
                  <div className="divsingle-cta1">
                    <div className="spanhs-cta-ae80ca34-8e08-402c">
                      <div className="link6">
                        <div className="start-for-free">Request Demo</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divspan121" data-animate-on-scroll>
          <FeatureCardForm />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default RestauranteatappcoByHtmlt;
