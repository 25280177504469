import "./RealTimeSupportContainer.css";

const RealTimeSupportContainer = ({
  see20everything20in20once,
  heading4SeeEverythingInOn,
  stopJugglingMultipleAppsS,
  bringItAllIntoOneEasyToUs,
  real20time20support20icon,
  heading4GetRealTimeSuppor,
  wereOnHand247ToHelpAlongT,
  inAppCustomerSupport,
}) => {
  return (
    <div className="frame4">
      <div className="divsingle-item1">
        <div className="span4">
          <img
            className="see20everything20in20once2-icon"
            alt=""
            src={see20everything20in20once}
          />
        </div>
        <div className="divproblem-content">
          <div className="heading-41">{heading4SeeEverythingInOn}</div>
          <div className="p7">
            <div className="stop-juggling-multiple-container">
              <p className="stop-juggling-multiple">
                {stopJugglingMultipleAppsS}
              </p>
              <p className="stop-juggling-multiple">
                {bringItAllIntoOneEasyToUs}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="divsingle-item1">
        <div className="span4">
          <img
            className="see20everything20in20once2-icon"
            alt=""
            src={real20time20support20icon}
          />
        </div>
        <div className="divproblem-content">
          <div className="heading-41">{heading4GetRealTimeSuppor}</div>
          <div className="p8">
            <div className="stop-juggling-multiple-container">
              <p className="stop-juggling-multiple">
                {wereOnHand247ToHelpAlongT}
              </p>
              <p className="stop-juggling-multiple">{inAppCustomerSupport}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealTimeSupportContainer;
