import "./Footer.css";

const Footer = () => {
  return (
    <div className="divrow-fluid-wrapper5">
      <div className="divrow-fluid2">
        <div className="divspan127">
          <div className="divhs-cos-wrapper-module-16925">
            <div className="spanhs-cos-wrapper-module-1697">
              <div className="p12">
                <div className="eat-app-all">
                  © Eat App. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
