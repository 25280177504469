import "./CardContainer.css";

const CardContainer = ({ dimensionCode, locationType }) => {
  return (
    <div className="divsingle-item">
      <div className="span3">
        <img className="restaurants20iconwebp" alt="" src={dimensionCode} />
      </div>
      <div className="p6">
        <b className="restaurants">{locationType}</b>
      </div>
    </div>
  );
};

export default CardContainer;
